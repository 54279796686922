<template>
  <div class="admin-audit">
    <!-- 搜索 -->
    <van-search
      v-model="searchValue"
      show-action
      placeholder="搜索员工姓名或联系电话"
      maxlength="11"
      @clear="onSearch"
      @search="onSearch"
    >
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <!-- 审核列表 -->
    <van-tabs v-model="active" @change="handleChangeTab" swipeable>
      <van-tab title="未审核" name="0">
        <p v-if="personAuditList.length === 0">暂无数据...</p>
        <van-list
          v-else
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <van-row v-for="(personAudit, index) in personAuditList" :key="index">
            <van-col span="12">
              姓名：
              <span>{{ personAudit.name }}</span>
            </van-col>
            <van-col span="12">
              联系电话：
              <span>{{ personAudit.mobile }}</span>
            </van-col>
            <van-divider />
            <van-col span="24">
              <van-button
                round
                size="small"
                type="warning"
                class="rejecte-botton"
                @click="editAudit(personAudit.id, 3)"
                >驳回</van-button
              >
              <van-button
                round
                size="small"
                type="primary"
                class="through-botton"
                @click="editAudit(personAudit.id, 1)"
                >通过</van-button
              >
            </van-col>
          </van-row>
        </van-list>
      </van-tab>
      <van-tab title="已审核" name="1">
        <p v-if="personAuditList.length === 0">暂无数据...</p>
        <van-list
          v-else
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <van-row v-for="(personAudit, index) in personAuditList" :key="index">
            <van-col span="12">
              姓名：
              <span>{{ personAudit.name }}</span>
            </van-col>
            <van-col span="12">
              联系电话：
              <span>{{ personAudit.mobile }}</span>
            </van-col>
            <van-divider />
            <van-col span="24">
              <van-button
                round
                size="small"
                type="danger"
                class="cancellation-botton"
                @click="editAudit(personAudit.id, 2)"
                >离职注销</van-button
              >
            </van-col>
          </van-row>
        </van-list>
      </van-tab>
    </van-tabs>
    <!-- 全局加载 -->
    <van-overlay :show="isShow">
      <van-loading type="spinner" color="#CC9756" />
    </van-overlay>
  </div>
</template>

<script>
import { getAdminlList, editAuditAdmin } from "@/api/api";
import { Dialog } from "vant";
export default {
  data() {
    return {
      // 全局加载
      isShow: true,
      // 搜索值
      searchValue: "",
      // tab方式
      tabType: 1,
      //审核选择
      active: 0,
      // 审核列表数据
      personAuditList: [],
      // 上拉加载
      loading: false,
      finished: false,
      page: 1, //请求第几页
      pageSize: 10, //每页请求的数量
      total: 3, //总共的数据条数
    };
  },
  created() {
    this.getAdminlList(1);
  },
  methods: {
    // tab切换选中值
    handleChangeTab() {
      this.page = 1;
      this.loading = false;
      this.finished = false;
      this.personAuditList = [];
      if (this.active === "0") {
        this.tabType = 1;
        this.getAdminlList(this.tabType);
      } else {
        this.tabType = 2;
        this.getAdminlList(this.tabType);
      }
    },
    // 获取管理员审核列表
    getAdminlList(type) {
      getAdminlList({
        type: type,
        search: this.searchValue,
        page: this.page,
      }).then((res) => {
        if (res.code === 200) {
          if (res.data.length === 0) {
            this.isShow = false;
          }
          // 加载状态结束
          this.loading = false;
          if (this.personAuditList.length >= res.meta.total) {
            this.finished = true;
            return;
          } else {
            this.personAuditList = this.personAuditList.concat(res.data);
            this.page++;
          }
          this.isShow = false;
        }
      });
    },
    // 搜索
    onSearch() {
      this.page = 1;
      this.loading = false;
      this.finished = false;
      this.personAuditList = [];
      this.getAdminlList(this.tabType);
    },
    // 数据加载
    onLoad() {
      this.getAdminlList(this.tabType);
    },
    // 审核通过、驳回、注销
    editAudit(id, state) {
      const editMessage =
        state === 3
          ? "管理员审核驳回？"
          : state === 1
          ? "管理员审核通过？"
          : "管理员离职注销？";
      const editSuccess =
        state === 1 ? "审核通过" : state === 3 ? "审核驳回" : "已注销";
      Dialog.confirm({
        message: editMessage,
        confirmButtonColor: "#f3973b",
      })
        .then(() => {
          // on confirm
          editAuditAdmin({ id: id, state: state }).then((res) => {
            if (res.code === 200) {
              this.$toast.success(editSuccess);
              this.personAuditList = [];
              this.page = 1;
              this.loading = false;
              this.finished = false;
              this.getAdminlList(this.tabType);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.admin-audit {
  margin-top: 26px;
}
.van-tabs {
  margin-top: 10px;
}
p {
  font-size: 14px;
  text-align: center;
  margin: 20px 0;
  color: #9a9a9a;
}
/deep/.van-tab--active {
  color: #f3973b;
  border-left: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
}
/deep/.van-tabs__line {
  width: 50%;
  height: 4px;
  background-color: #f3973b;
}
.van-row {
  min-width: 100%;
  height: 116px;
  background-color: #fff;
  margin-bottom: 10px;
  font-size: 13px;
  .van-col:nth-child(1),
  .van-col:nth-child(2) {
    font-size: 13px;
    color: #9a9a9a;
    padding-top: 20px;
    padding-bottom: 22px;
    span {
      color: #000;
      font-size: 13px;
    }
  }
  .van-col:nth-child(1) {
    padding-left: 23px;
  }
  .van-divider {
    border-color: #eaeaea;
    padding: 0 16px;
  }
  .van-col:nth-child(4) {
    display: flex;
    flex-direction: inherit;
    justify-content: flex-end;
  }
  .van-button {
    width: 80px;
    font-size: 13px;
    margin-right: 16px;
  }
  .rejecte-botton {
    background-color: #f3983c;
    border: #f3983c;
  }
  .through-botton {
    background-color: #52c898;
    border: #52c898;
  }
  .cancellation-botton {
    background-color: #fe816e;
    border: #fe816e;
  }
}
</style>
